import './App.css';

import React, {useContext, useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import axios from 'axios'
import fileDownload from 'js-file-download'
import moment from "moment";

import { GlobalDataProvider } from "./index";

import Home from "./components/home";
import Header from "./components/header";
import ReportList from "./components/ReportList"
import CryptoJS from "crypto-js";

function App() {
  const { globalData } = useContext(GlobalDataProvider);
    const yearTab = ["2025","2024","2023", "2022", "2021"]
    const [notifData, setNotifData] = React.useState(null);
    const [data, setData] = React.useState([]);

    const getData = async (client) => {
        let year = yearTab[0]
        let filename = "reportList.txt"
        if(year !== "2021") {
            filename = filename.split(".")[0] + year + "." +filename.split(".")[1]
        }
        return await fetch(
            `data/${client}/${filename}`,
            {
                headers: {
                    'Content-Type': 'application/text',
                    'Accept': 'application/text'
                }
            }
        )
            .then((response) => {
                return response.text();
            })
    }

    useEffect(() => {
        getData(globalData.clientId).then(res => {
            const bytes  = CryptoJS.AES.decrypt(res, globalData.privateKey);
            const decryptedData = JSON.parse(bytes.toString((CryptoJS.enc.Utf8)));
            setData(decryptedData)
        })
    }, [globalData.clientId, globalData.privateKey])

    useEffect(() => {
        let notifArray = []

        data.forEach((country, index) => {
            for (let item in country.data) {
                country.data[item].map((report) => {
                    return report
                }).filter((r) => {
                    if(r.hasOwnProperty("lastModified")) {
                        const given = moment(r.lastModified, "DD/MM/YYYY");
                        const current = moment().startOf('day');
                        const duration = moment.duration(given.diff(current)).asDays();

                        if(duration >= -6) {
                            notifArray.push({
                                country: country.country,
                                report: item,
                                detail: r
                            })
                        }

                    }
                })
            }
        })
        setNotifData(notifArray)
    }, [data])

    const handleDownload = (url, file) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, file)
            })
    }

    return (
      <>
          <Header
              notifList={notifData}
              client={globalData.clientId}
              handleDownload={handleDownload}
          />
          <Router>
              <div>
                  <Switch>
                      {
                          globalData && globalData.countries.map((country) => {
                              return (
                                  <Route path={`/${country}`}>
                                      <ReportList
                                          client={globalData.clientId}
                                          privateKey={globalData.privateKey}
                                          country={country}
                                          yearTab={yearTab}
                                      />
                                  </Route>
                              )
                          })
                      }
                      <Route path="/">
                          <Home />
                      </Route>
                  </Switch>
              </div>
          </Router>
      </>
  );
}

export default App;
